import {
	PREDICTION_TYPE_ANOMALY,
	PREDICTION_TYPE_PROLONGED_ANOMALY,
	PREDICTION_TYPE_STATE_CHANGE,
	PREDICTION_TYPE_TARGET,
	PREDICTION_TYPE_TARGET_RT,
	PREDICTION_TYPE_SET_POINT
} from '@/lib/constants';

export default class WellHistoryCommentMaker {
	tasq: any;
  comment: any;

  constructor(tasq, comment) {
    this.tasq = tasq;
    this.comment = comment;
  }

  getReadableComment(): string {
    let readableComment: string = '';
		if (this.comment.ResponseData == null) {
			readableComment = this.handleNullResponseData()
		} else if (this.comment.ResponseData.UserResponse != null) {
			readableComment = this.handleUserResponse()
		} else {
			readableComment = this.handleResponseData()
		}

		if (this.isPredictionTypeOffTargetRT()) {
			if (this.comment.Payload != null && this.comment.Payload.Outcome != null) {
			  readableComment += ` - ${this.comment.Payload.Outcome}`;
			}
		}

		readableComment = readableComment.replace('Off-Target RT', 'Off-Target Real Time');
		return readableComment;
  }

  handleNullResponseData (): string {
  	let readableComment: string = 'Confirmed';
		if (this.comment.Type != null && this.comment.Type == 'CYGNET') {
		  readableComment = 'Comment';
		}else if (this.comment.Type != null && this.comment.Type == 'TASQ_REASSIGNMENT') {
			readableComment = 'Reassignment'
		}
		return readableComment
  }

  handleUserResponse (): string {
  	let readableComment: string = 'Confirmed';
  	const userResponse: any = this.comment.ResponseData.UserResponse;
  	const selection: string = userResponse.Selection;
  	if (selection == 'YES') {
		  readableComment = 'Confirmed';
		} else if (selection == 'NO') {
		  readableComment = 'No';
		} else if (selection == 'NOT_SURE') {
		  readableComment = 'Not sure of';
		} else if (selection == 'AUTO') {
		  readableComment = 'Closed automatically';
		}

		if (selection != 'AUTO' && (
			this.testCommentPredictionType([PREDICTION_TYPE_ANOMALY, PREDICTION_TYPE_STATE_CHANGE]) ||
			this.testCommentPredictionType([PREDICTION_TYPE_ANOMALY, PREDICTION_TYPE_STATE_CHANGE, PREDICTION_TYPE_PROLONGED_ANOMALY]))) {
		  readableComment += ' - Anomaly';
		} else if (this.isPredictionTypeOffTarget()) {
		  readableComment += ' - Off-Target';
		} else if (this.isPredictionTypeOffTargetRT()) {
		  readableComment += ' - Off-Target RT';
		} else if (this.testCommentPredictionType(PREDICTION_TYPE_SET_POINT)) {
		  readableComment += ' - Setpoint';
		}

		const reason: any[] = userResponse.SelectionDetails.Reason;
		if (selection != 'AUTO' && reason.length > 0) {
		  readableComment += ': ';
		  for (let x = 0; x < reason.length; x++) {
		    readableComment = `${readableComment} ${reason[0]}`;
		  }
		}
		return readableComment
  }

  handleResponseData (): string {
  	let readableComment: string = 'Confirmed';
  	const validate = this.comment.ResponseData.Validate
  	if (validate == 'YES') {
		  readableComment = 'Confirmed';
		} else if (validate == 'NO') {
		  readableComment = 'No';
		} else if (validate == 'NOT_SURE') {
		  readableComment = 'Not sure of';
		} else if (validate == 'AUTO') {
		  readableComment = 'Closed automatically';
		}
		if (this.isPredictionTypeOffTarget()) {
		  readableComment += ' - Off-Target';
		} else if (this.isPredictionTypeOffTargetRT()) {
		  readableComment += ' - Off-Target RT';
		} else if (this.testCommentPredictionType(PREDICTION_TYPE_SET_POINT)) {
		  readableComment += ' - Setpoint';
		} else {
		  readableComment += ` - ${this.comment.PredictionType}`;
		}
		return readableComment
  }

  isPredictionTypeOffTarget(): boolean {
  	return this.testCommentPredictionType(PREDICTION_TYPE_TARGET) || this.testTasqPredictionType(PREDICTION_TYPE_TARGET)
  }

  isPredictionTypeOffTargetRT(): boolean {
  	return this.testCommentPredictionType(PREDICTION_TYPE_TARGET_RT) || this.testTasqPredictionType(PREDICTION_TYPE_TARGET_RT)
  }

  testCommentPredictionType (values: string | string[]): boolean {
  	const predictionType = this.comment.PredictionType
  	if (!predictionType) {
  		return false
  	}
  	values = [values].flat()
  	return values.includes(predictionType.toLowerCase())
  }

  testTasqPredictionType (values: string | string[]): boolean {
  	if (this.tasq == undefined) {
  		return false
  	}
  	const predictionType = this.tasq.predictionType
  	values = [values].flat()
  	return values.includes(predictionType.toLowerCase())
  }
}
