
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import assetsModule from '@/store/modules/assetsModule';
import {
  getComponent,
  sleep,
  getFormattedDate as getFormattedDateHelper,
} from '@/utils/helpers';
import draggable from 'vuedraggable';
import Multiselect from 'vue-multiselect';
import scheduleModule from '@/store/modules/scheduleModule';
import DateRangePicker from 'vue2-daterange-picker';
// you need to import the CSS manually
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import tasqsListModule from '@/store/modules/tasqsListModule';
import TasqJob from '@/interfaces/tasqs/TasqJob';
import { setTimeout } from 'timers';
import WellHistoryCommentMaker from '@/lib/wellHistoryCommentMaker';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    Multiselect,
    TasqsDetails: () => getComponent('tasqs/TasqsDetails'),
    Dropdown: () => getComponent('common/Dropdown'),
    TasqsMobile: () => getComponent('tasqs/TasqDetailMobile'),
    DateRangePicker,
    draggable,
  },
})
export default class Groups extends Vue {
  @Prop({ type: Object, required: false }) wellEventDetails?: any;

  windowWidth = window.innerWidth;

  dataLoading = true;

  get isMobile() {
    return this.windowWidth < 768;
  }

  get currentWellType(): any {
    if (tasqsListModule.activeTasq && this.$route.query.type != 'producing') {
      return 'id';
    }

    if (assetsModule.activeTasq) {
      if (scheduleModule.activeComponent) {
        return 'scheduled';
      }

      return 'producing';
    }
    return this.$route.query.type;
  }

  get tasq() {
    if (tasqsListModule.isBatchResponding) {
      if (!tasqsListModule.activeTasq) {
        tasqsListModule.setActiveTasq(tasqsListModule.checkedTasqs[0]);
      }
      return tasqsListModule.activeTasq as TasqJob;
    }

    if (assetsModule.activeTasq == undefined) {
      return tasqsListModule.activeTasq as TasqJob;
    }

    if (this.currentWellType == 'producing') {
      return assetsModule.activeTasq as TasqJob;
    }

    return tasqsListModule.activeTasq as TasqJob;
  }

  getReadableWellHistoryComment(comment) {
    let wellHistoryCommentMaker = new WellHistoryCommentMaker(
      this.tasq,
      comment
    );
    return wellHistoryCommentMaker.getReadableComment();
  }

  created() {
    console.log(this.wellEventDetails);
    setTimeout(() => {
      this.dataLoading = false;
    }, 1000);
  }

  getFormattedDate(date) {
    return getFormattedDateHelper(date);
  }
}
